import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import rightArrow from "../assets/img/right-arrow.png";
import googleAds from "../assets/img/google-img1.png"
import sefasone from "../assets/img/sefastone-logo.png"
import wave from "../assets/img/wave.png"
import seoAudits from "../assets/img/seo-audits.png"
import seohizmet from "../assets/img/seo-hizmet.png"
import onePageSeo from "../assets/img/one-page-seo.png"
import offPageSeo from "../assets/img/off-page-seo.png"
import seoTools from "../assets/img/seo-tools.png"
import Teams from "../components/teams/seo-team"
import SeoSss from "../components/sss/seo-sss"
import checkModal from "../assets/img/check-modal.png";
import Companys from "../components/seo-companys"
import liva from "../assets/img/companys/17.png"
import tcdd from "../assets/img/companys/15.png"
import enerjisa from "../assets/img/companys/38.png"
import kuruyemis from "../assets/img/companys/40.png"


class Seo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            display: false,
            value: ''
        };
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e) {
        e.preventDefault();

        this.setState({ value: e.target.value });
    }
    showModal() {
        this.setState({ display: !this.state.display });
        document.querySelector('.check-modal').classList.add('active-modal');
    }
    closeModal() {
        this.setState({ display: !this.state.display })
        document.querySelector('.check-modal').classList.remove('active-modal');
    }
    handleSubmit(e) {
        e.preventDefault();
        const title = document.title.split('|', 1).toString();
        const response = fetch('https://forms.flatart.com.tr/index.php?r=app%2Ff&id=7', {
            method: 'POST',
            body: JSON.stringify({
                "text_327649": title,
                "text_204202": document.querySelector('#site').value,
                "text_775908": document.querySelector('#name').value,
                "text_532192": document.querySelector('#email').value,
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        });
        response.then(res => {
            if (res.status == 200) {
                document.querySelector('.status').innerHTML = "Mesajınız başarıyla gönderilmiştir.";
            }
            else {
                document.querySelector('.status').innerHTML = "Bir hata oluştu.";
            }
        });
    }
    render() {
        return (
            <Layout>
                <SEO title="Seo Ajansı: Organik Trafik Hacminizi Flatart ile Büyütün" description="Seo alanında uzman ekibimiz ile Google Arama Sonuçlarında sıralamalarınızı iyileştirin, site otoritenizi ve organik trafiğinizi arttırın." />
                <div className="seo-service container">
                    <div className="seo-service__head" >
                        <div className="col-md-6 noPadding">
                            <h1>Arama Motoru <br />Optimizasyonu</h1>
                            <span className="red-head">HİZMETLER</span>
                            <p>Şu ana kadar 125 markada yaptığımız gibi markaların, Google ve diğer arama motorları üzerinde arama sonuçlarında daha üst sıralara çıkmasını ve organik satışları artırmasını sağlıyoruz.</p>
                            <input type="text" className="pink-input" placeholder="Websitenizi giriniz." value={this.state.value} onChange={this.handleChange} />
                            <button id="seo-button" className="red-button" onClick={(e) => this.showModal()}>Ücretsiz SEO checkup <img alt="alt text" src={rightArrow}></img></button>
                        </div>
                        <div className="col-md-6 seo-top-right" style={{ paddingLeft: 130, marginTop: 40 }}><img alt="alt text" id="seo-page-img" src={googleAds} style={{ width: '90%' }} /> </div>
                        <div className="check-modal">
                            <span onClick={(e) => this.closeModal()}>X</span>
                            <h2 className="form-head">Formu doldurun.</h2>
                            <p className="form-desc">Bilgi almak için bilgilerinizi formdaki alanlara girin, en kısa sürede size dönüş yapalım.</p>
                            <div className="check-modal_container">
                                <form onSubmit={this.handleSubmit} >
                                    <input type="text" name="text_204202" id="site" required  value={this.state.value} onChange={this.handleChange} autoFocus={true} placeholder="Websitenizi girin." />
                                    <input type="text" id="name" required name="text_775908" placeholder="Adınızı girin." />
                                    <input type="email" id="email" required  name="text_532192"  placeholder="E-posta adresinizi girin." />
                                    <button  >GÖNDER</button>
                                    <div className="status"></div>
                                </form>
                                <img alt="alt text" src={checkModal} />
                            </div>
                        </div>
                    </div>
                    <div className="studies">
                        <p>Kanıtlanmış SEO stratejilerimizle
                            markaların websitesine yılda ortalama
                            %81 daha fazla trafik göndermelerini sağladık.
                        </p>
                        <div>
                            <span className="red-head">BAŞARI HİKAYELERİ</span>
                        </div>
                    </div>
                    <div className="clients-customers">
                        <div>
                            <span>+90k</span>
                            <p>Aylık edinilen organik trafik.</p>
                            <Link t ="/basari-hikayeleri/liva-pastacilik/">Çalışmanın detaylarına göz atın</Link><br />
                            <img alt="alt text" src={liva}></img>
                        </div>
                        <div>
                            <span>+%125</span>
                            <p>Organik trafik artışı.</p>
                            <Link to="/basari-hikayeleri/tcdd-tasimacilik/">Çalışmanın detaylarına göz atın</Link><br />
                            <img alt="alt text" src={tcdd}></img>
                        </div>
                       
                        <div>
                            <span>%34</span>
                            <p>Yıllık ciroda organik trafik artışı.</p>
                            <Link to="/basari-hikayeleri/kuruyemis-borsasi/">Çalışmanın detaylarına göz atın</Link><br />
                            <img alt="alt text" src={kuruyemis}></img>
                        </div>
                        <div>
                            <span>8.2k</span>
                            <p>Aylık ortalama organik anahtar kelime sayısı.</p>
                            <Link to="/basari-hikayeleri/enerjisa/">Çalışmanın detaylarına göz atın</Link><br />
                            <img alt="alt text" src={enerjisa}></img>
                        </div>
                    
                        
                    </div>

                </div>
                <div className="wave">
                    <img alt="alt text" src={wave}></img>
                </div>

                <div className="seo-referances container">
                    <h2>SEO Referanslarımız</h2>
                    <p>Öne çıkan referans markalar.</p>
                    <Companys />
                </div>

                <div className="seo-process py60 container">
                    <h2>SEO Süreci</h2>
                    <div className="circles">
                        <div className="circle">
                            <h3><a href="#seo-audits">SEO Audit & <br />Strateji Planlama</a></h3>
                            <p>Bilinçli bir şekilde oluşturulan SEO stratejisi daha iyi sonuçlar verir. Her çalışmaya teknik, görünürlük ve optimizasyon analizi içeren bir denetim ile başlıyoruz.</p>
                        </div>
                        <svg id="right-chevron" xmlns="http://www.w3.org/2000/svg" width="8.51" height="13.5" viewBox="0 0 8.51 13.5">
                            <path id="Path_449" data-name="Path 449" d="M90.411,5.97,84.761.329a1.088,1.088,0,0,0-1.56,0l-.659.65a1.061,1.061,0,0,0-.321.78,1.109,1.109,0,0,0,.321.789l4.211,4.2-4.211,4.211a1.061,1.061,0,0,0-.321.78,1.109,1.109,0,0,0,.321.789l.659.65a1.109,1.109,0,0,0,1.56,0l5.65-5.641a1.109,1.109,0,0,0,.321-.789A1.061,1.061,0,0,0,90.411,5.97Z" transform="translate(-82.222 0)" fill="#ea4f6a" />
                        </svg>

                        <div className="circle">
                            <h3><a href="#on-pageSeo">On-Page SEO</a></h3>
                            <p>Google arama sonucu sıralamasında üst sıralarda yer almak ve kaliteli trafik edinmek için web sayfalarınızı teknik ve kullanıcı odaklı optimize diyoruz.</p>
                        </div>
                        <svg id="right-chevron" xmlns="http://www.w3.org/2000/svg" width="8.51" height="13.5" viewBox="0 0 8.51 13.5">
                            <path id="Path_449" data-name="Path 449" d="M90.411,5.97,84.761.329a1.088,1.088,0,0,0-1.56,0l-.659.65a1.061,1.061,0,0,0-.321.78,1.109,1.109,0,0,0,.321.789l4.211,4.2-4.211,4.211a1.061,1.061,0,0,0-.321.78,1.109,1.109,0,0,0,.321.789l.659.65a1.109,1.109,0,0,0,1.56,0l5.65-5.641a1.109,1.109,0,0,0,.321-.789A1.061,1.061,0,0,0,90.411,5.97Z" transform="translate(-82.222 0)" fill="#ea4f6a" />
                        </svg>

                        <div className="circle">
                            <h3><a href="#off-pageSeo">Off-Page SEO</a></h3>
                            <p>Google arama sonucu sıralamasını iyileştirmek ve organik trafiği artırmak için websitesi dışında da SEO çalışmaları yapıyoruz.</p>
                        </div>
                    </div>
                </div>

                <div className="seo-services container">
                    <div className="seo-services_head">
                        <h2>Markanızı SEO ile <br />Büyütmenin 3 Temel Yolu</h2>
                        <span className="red-head">SEO HİZMETLERİ</span> <br />
                        <img alt="alt text" src={seohizmet} style={{marginBottom:-100, width:'60%', height:'auto' }} />

                    </div>
                    <div className="seo-services_content py60 test" id="seo-audits">
                        <div className="col-md-4 seoajansi">
                            <img alt="alt text" class="seoaudits" src={seoAudits}  />
                            <h2>SEO Audits</h2>
                            <p>SEO çalışmalarımızın ilk adımı olarak SEO uzmanlarımız ve denetim araçları yardımıyla websitenizin SEO performansını inceliyor, sağda gördüğünüz düzeylerde zayıf noktaları tespit ediyoruz.</p>
                        </div>
                        <div className="col-md-8 content-list">
                            <div className="col-md-6 audits-info">
                                <h3>Anahtar Kelime Analizi</h3>
                                <p>Google Search Console, Ahrefs, SEMrush, SEOmonitor gibi yazılımları kullanarak trafiğinizin çoğunu oluşturan, ilerleme kaydetmeyen ve geliştirilmesi gereken anahtar kelimeleri analiz ediyoruz.</p>
                            </div>
                            <div className="col-md-6 audits-info">
                                <h3>Rekabet Analizi</h3>
                                <p>Rekabeti ele almanızı sağlamak için rekabet analizi yapmak kritik bir önem taşıyor. </p>
                                <p>Google arama sonuçlarında kimlerle rekabet ettiğinizi tespit ediyor, rakiplerinizin üstüne çıkmanızı engelleyen durumları gözetiyoruz.</p>
                            </div>
                            <div className="col-md-6 audits-info">
                                <h3>Teknik Analiz</h3>
                                <p>SEO başarısında websitenin kullanıcı deneyimi uygunluğu kilit bir rol oynuyor. </p>
                                <p>Teknik analiz yaparak websitenizin mobil dostu olup olmadığını, sertifikalarını, yüklenme hızını, yinelenen içeriklerini vb. konuları inceliyor, zayıf yönleri raporluyor ve faaliyete geçirilmesini sağlıyoruz.</p>
                            </div>
                            <div className="col-md-6 audits-info">
                                <h3>Page-Level Analiz</h3>
                                <p>Sadece önemli ya da öne çıkan sayfalar değil websitesindeki her sayfanın en iyi duruma getirilmesi için içerikleri meta açıklamalarından URL’ine, anahtar kelime sıklığından link inşasına kadar her yönüyle denetliyoruz. </p>
                            </div>
                            <div className="col-md-6 audits-info">
                                <h3>İçerik Kalitesi <br />Analizi</h3>
                                <p>Websitesi içeriklerinin tutarlı, kullanıcıya faydalı ve doğru bilgiler içermesi, SEO performansının temelini oluşturan ögelerden. </p>
                                <p>İçeriklerin orijinalliğini ve kalitesini değerlendiriyor, SEO performansını olumsuz etkileyen noktaları tespit ediyoruz.</p>
                            </div>
                            <div className="col-md-6 audits-info">
                                <h3>Kullanıcı Deneyimi<br />Analizi</h3>
                                <p>Kullanıcıların websitesinde geçirdikleri süre, hemen çıkma oranları, aradıkları içeriklere ulaşma sürecinde yaşadıkları deneyimi inceliyor, genel durumu ortaya koyan istatistikleri anlamlandırıyoruz.</p>
                            </div>
                            <div className="col-md-6 audits-info">
                                <h3>Full Backlink Profil<br />Analizi</h3>
                                <p>Tüm link’ler önemlidir, ama bazı link’ler daha önemlidir. </p>
                                <p>Backlink profilinizi görüntüleyerek benzer nişe sahip websitelerinden gelen backlink sayısına, backlink türüne, kullanılan anchor text’lere ve daha fazlasına bakıyoruz.</p>
                            </div>
                            <div className="col-md-6 audits-info">
                                <h3>Size Özel SEO <br />Strateji Planı</h3>
                                <p>Kalıcı başarı için analiz verilerini değerlendiriyor ve markanız için en doğru stratejiyi geliştiriyoruz. </p>
                                <p>Hayata geçirilebilir hedeflere odaklanan strateji planı ile websitenizde istikralı bir trafik artışı yaratarak dönüşümlerinizi artırıyoruz.</p>
                            </div>
                        </div>
                    </div>
                    <div className="seo-services_content py60 test" id="on-pageSeo">
                        <div className="col-md-4 seoajansi">
                            <img alt="alt text" class="seoaudits" src={onePageSeo} />
                            <h2>On-Page SEO</h2>
                            <p>Analiz sonucunda tüm web sayfalarınızda SEO standartlarına uygun olmayan noktaları belirleyip kullanıcı deneyimi odağında sağda gördüğünüz düzeylerde gereken iyileştirmeleri gerçekleştiriyoruz.</p>
                        </div>
                        <div className="col-md-8 one-page-seo content-list" >
                            <div className="col-md-6 audits-info">
                                <h3>Optimizasyon</h3>
                                <p>- Sayfa Başlığı Düzenleme<br />
                                    - Eksik Sayfa Başlıkları<br />
                                    - Çok Uzun/Kısa Sayfa Başlıkları<br />
                                    - Yinelenen Sayfa Başlıkları<br />
                                    - Eksik Meta Açıklamaları<br />
                                    - Çok Uzun/Kısa Meta Açıklamaları<br />
                                    - Yinelenen Meta Açıklamaları<br />
                                    - Alt Başlık Hiyerarşisi
                       </p>
                            </div>
                            <div className="col-md-6 audits-info">
                                <h3>Anahtar Kelimeler</h3>
                                <p>
                                    - Anahtar Kelime Analizi<br />
                                    - Rekabet Analizi<br />
                                    - Aranma Hacmi Düşük Anahtar Kelime Aranmaları<br />
                                    - Domain Otorite Kıyaslaması <br />
                                    - Long Tail Anahtar Kelime Seçimi<br />
                                    - Alt Sayfa Anahtar Kelime Hiyerarşisi<br />
                                    - Anahtar Kelime Sıklığı<br />
                                    - Yanlış Anchor Text Link’leme
                       </p>
                            </div>
                            <div className="col-md-6 audits-info">
                                <h3>İndeksleme</h3>
                                <p>
                                    - Robots.txt Dosyası ile Engellenen Değerli İçerikler<br />
                                    - Robots.txt Dosyasında Listelenmeyen URL veya Eksik XML Site Haritası <br />
                                    - Robots.txt tarafından engellenen XML Site Haritası Sayfaları <br />
                                    - Hatalı/Tamamlanmamış/Güncel Olmayan XML Site Haritası<br />
                                    - Eksik XML Video and Görsel Site Haritası <br />
                                    - Düşük Sayfa Hızı Skoru <br />
                                    - Kullanım Dışı Webmaster Araçları<br />
                                    - Yanlış Canonical Etiketler
                       </p>
                            </div>
                            <div className="col-md-6 audits-info">
                                <h3>URL Yapısı</h3>
                                <p>
                                    - Gereksiz Sub-Domain’ler<br />
                                    - Gereksiz Dizinler<br />
                                    - Jenerik Directory Yapısı<br />
                                    - Dinamik URL Sorunları<br />
                                    - URL Parametlerini İzleme<br />
                                    - URL’deki Oturum Kimlikleri
                       </p>
                            </div>
                            <div className="col-md-6 audits-info">
                                <h3>Link’leme</h3>
                                <p>
                                    - İç Link’leme Sorunları<br />
                                    - Yetersiz Anchor Text<br />
                                    - Eksik Breadcrumb’lar<br />
                                    - Hatalı Breadcrumb’lar<br />
                                    - Breadcrumb’ları Eksik Schema<br />
                                    - Kullanıcının Erişimi Olmayan Site Haritası<br />
                                    - İç Link’lemede Gereksiz No-Follow Link’leri<br />
                                    - Javascript Navigasyonu<br />
                                    - Navigasyonda Kullanılan Görseller<br />
                                    - Flash Navigasyon
                       </p>
                            </div>
                            <div className="col-md-6 audits-info">
                                <h3>Site Hataları</h3>
                                <p>
                                    - Sayfa Hataları İçeren Site<br />
                                    - Kırık Sayfa Link’leri<br />
                                    - Özelleştirilmemiş 404 Sayfa Hatası<br />
                                    - 404’e Dönüş Yapmayan Sayfalar
                       </p>
                            </div>
                        </div>

                    </div>
                    <div className="seo-services_content py60 test" id="off-pageSeo">
                        <div className="col-md-4 seoajansi">
                            <img alt="alt text" class="seoaudits" src={offPageSeo} />
                            <h2>Off-Page SEO</h2>
                            <p>Websitenizin arama motorlarında güvenilir bir mecra olarak kabul görmesi ve dolayısıyla arama sonuçlarında üst sıralarda yer alması için sağda gördüğünüz düzeylerde çalışmalar yaparak websitenizin trafik akışını artırıyoruz.</p>
                        </div>
                        <div className="col-md-8 content-list">
                            <div className="col-md-6 audits-info">
                                <h3>Link İnşası</h3>
                                <p>Başka websitelerinden sizin websitenize yönlendirilen link’lerin sayısı ve kalitesi SEO için oldukça önemlidir. </p>
                                <p>Rekabette ön plana çıkmanızı sağlayacak ve trafiği artıracak bir link inşası yapıyoruz.</p>
                            </div>
                            <div className="col-md-6 audits-info">
                                <h3>SEO Kampanyaları</h3>
                                <p>Potansiyel müşterilerinizin websitenize hızla ulaşması, iyi bir deneyim yaşaması ve birer müşteriye dönüşmesi için stratejik plana göre SEO kampanyaları kurguluyoruz.</p>
                                <p>Kampanyalarda mikro site ve tool geliştirerek insanların fayda sağlayacağı projeler üretiyoruz.</p>
                            </div>
                            <div className="col-md-6 audits-info">
                                <h3>Anchor Text<br />Optimizasyonu</h3>
                                <p>Websitenizin güçlü ve doğal bir backlink profiline sahip olmasında doğru anchor text kullanımı büyük önem taşıyor. </p>
                                <p>Stratejik özellikte anchor text optimizasyonları yapıyor, SEO performansınızı artırıyoruz.</p>
                            </div>
                            <div className="col-md-6 audits-info">
                                <h3>İçerik Dağıtımı</h3>
                                <p>Website içeriklerinizi doğru kitleyle buluşturmak için doğru kanallar ve medya formatlarını kullanıyoruz.</p>
                                <p>Üretilen içeriğin Medium gibi 12 farklı içerik platformunda dağıtımını yapıyor, bu yolla, söz konusu mecralardaki kitleye doğrudan erişim sağlıyoruz.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="seo-tools container py60" id="seo-tools">
                    <h2>Kullandığımız SEO araçları</h2>
                    <p>Markanızı rekabette öne çıkarmak için kullandığımız profesyonel araçlar.</p>
                    <img alt="alt text" src={seoTools}></img>
                </div>

                {/*<div className="team">
                    <div className="py60">
                        <h2 style={{ textAlign: 'center' }}>Size yardımcı olabilecek <br /> SEO uzmanlarımız.</h2>
                    </div>
                    <Teams />
                </div>*/}

                <div className="hiring" style={{ marginTop: 50 }}>
                    <h2>Sıkça Sorulan Sorular</h2>
                    <SeoSss />
                </div>
            </Layout>
        )
    }
}
export default Seo
